import * as React from 'react';

export default ({ height, width }: any) => ( 
  <svg
    width={width}
    height={height}
    viewBox="0 0 71 66"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      transform="translate(-25.000000, -1228.000000) translate(0.000000, 1011.000000) translate(25.000000, 218.000000)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M10.182 38.497V38.4c-4.17-3.976-6.788-9.6-6.788-15.806 0-11.636 9.115-21.14 20.558-21.818-.582 0-1.164-.097-1.746-.097C10.182.679.388 10.473.388 22.497c0 6.206 2.618 11.83 6.788 15.806v.097L6.4 62.836c0 .582.388 1.067.97 1.067L28.8 64h.097l-19.588-.097.873-25.406z"
        fill="currentColor"
      />
      <path
        d="M50.812 27.055v-.68l-5.527-1.163c-.582-.194-1.067-.679-1.067-1.357 0-.68.485-1.164 1.067-1.358l5.236-1.552c-.485-2.715-1.454-5.527-2.909-8.339l-.29-.582c0-.097-.098-.097-.098-.194C43.442 5.14 36.267.68 28.121.68c-12.024 0-21.818 9.794-21.818 21.818 0 6.206 2.618 11.83 6.788 15.806v.097l-.776 24.436c0 .582.388 1.067.97 1.067l21.43.097c.582 0 .97-.485.97-.97v-9.89c0-.582.485-1.067 1.067-1.164l9.115-1.067c2.424-.29 4.363-2.23 4.557-4.654l.097-.776v-.291l.097-7.758 3.006-.775c1.164-.291 1.746-1.649 1.164-2.716l-3.976-6.884z"
        stroke="currentColor"
        strokeWidth={2}
      />
      <path
        d="M68.752 15.418l-18.134 5.43s.194.97.291 3.007c.097 1.26.097 2.52.097 2.52l17.842 3.977s1.164-2.328 1.261-7.467c-.194-4.849-1.357-7.467-1.357-7.467z"
        fill="currentColor"
      />
    </g>
  </svg>
)
